export enum ToasterType {
    SUCCESS = "SUCCESS"
    , WARNING = "WARNING"
    , DANGER = "DANGER"
    , INFO = "INFO"
    , NONE = "NONE"
}


export class ToasterModel {
    ID: string;
    Text: string;
    Type: ToasterType;
    Timeout: ToasterTimeout;
    Hide: boolean;
}

export type ToasterTimeout = "default" | "never" | number;