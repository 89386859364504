import { ToasterTexts } from '../components/shared/constants';
import { ToasterType } from '../components/shared/toaster/toaster.model';
import Config from '../config';
import { AxiosInstance } from '../helpers/AxiosInstance';
import setToast from '../helpers/toaster.helper';


const downLoadFile = (referenceId: any) => {
  return AxiosInstance.get(`${Config.API_URL}/api/File/${referenceId}/download`, { responseType: 'arraybuffer' });
};

const downLoadFileByURL = (url: string) => {
  return AxiosInstance.get(url, { responseType: 'arraybuffer' });
};

const viewFile = (referenceId: any) => {
  if (!referenceId) {
    return new Promise((resolve, reject) => {
      reject("referenceId is invalid");
    });
  }
  return AxiosInstance.get(`${Config.API_URL}/api/File/${referenceId}/view`, { responseType: 'arraybuffer' });
};

const UploadFile = (referenceId: any) => {
  return AxiosInstance.get(`${Config.API_URL}/api/File/${referenceId}_en/upload`, { responseType: 'arraybuffer' });
};

const UploadNewFile = (file: any, fileName: string, fileType: any) => {
  const config = fileName && fileType ? {
    headers: {
      "file-name": fileName,
      "file-type": fileType
    }
  } : undefined;
  console.log("files", file, fileName, fileType)
  return AxiosInstance.post(`${Config.API_URL}/api/File/upload`, file, config);
};

const fileDownload = (media) => {
  if (!!media) {
      if (media.ID) {
          FileService.downLoadFile(media.ID).then((response) => {
              setToast(ToasterTexts.FILE_DOWNLOAD_SUCCESS, ToasterType.SUCCESS)
              const url = URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", media.Name);
              link.click();
          });
      }
      else {
          setToast(ToasterTexts.FILE_DOWNLOAD_SUCCESS, ToasterType.SUCCESS);
          const link = document.createElement("a");
          link.href = media.Url;
          link.setAttribute("download", media.Name);
          link.click();
      }
  }
}

export const FileService = {
  downLoadFile,
  viewFile,
  UploadFile,
  downLoadFileByURL,
  UploadNewFile,
  fileDownload
};
