import { ToasterModel } from './toaster.model'
import Toaster from './toaster.component'
import * as selector from '../../../selectors/selectors'
import { useSelector } from 'react-redux';


interface Props { }

const ToasterContainerComponent = (props: Props) => {
    const Toasts: ToasterModel[] = useSelector(selector.toasts)

    return (
        <section className={`p-absolute toaster-container ${Toasts.length ? 'open' : 'close'}`} >
            {Toasts.map((x, i) => (
                <Toaster key={x.ID} {...x} Index={i} />
            ))}
        </section>
    )
}


export default ToasterContainerComponent;