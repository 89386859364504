import { CampaignModel, Contact, Group, MediaModel } from ".";
import Config from "../../config";
import { UtilityService } from "../../services/UtilityService";

export class ScheduledMessageModel {
    constructor() { }

    ID: string;
    Type: ScheduleMessageType;
    PhoneNumberID: string;
    Names: string[];
    Message: string;
    Status: ScheduleMessageStatus;
    IsDeleted: boolean;
    CreatedDateTime: string;
    NextScheduledDateTime: string;
    LastSentDateTime: string;
    Groups: Group[];
    Contacts: Contact[];
    PhoneNumbers: string[];
    CronExpression: string;
    Media?: MediaModel;
    Campaign?: CampaignModel | null;
}
export const getNameString = (scheuledMessage?: ScheduledMessageModel): string => {
    if (!scheuledMessage) {
        return ""
    }
    if (scheuledMessage.Names.length <= 0) {
        return ""
    }

    let count = scheuledMessage.Names.length - 1;
    return `${scheuledMessage.Names[0]} ${count ? "+" : ""} ${count > 1 ? `${count} Others` : count === 1 ? `${count} Other` : ""}`.trim();
}

export const mapToScheduledMessage = (x: any, contacts: Contact[], groups: Group[]) => {
    let groupsAdded = groups.filter(g => x.groups.includes(g.ID));
    let contactsAdded = contacts.filter(c => x.contacts.includes(c.ID))
    let numbers = []
    numbers = x.numbers.map(number => {
        let savedContactName = contacts.filter(c => c.PhoneNumber == number)[0]?.Name
        if (!!savedContactName) {
            return {
                Name: savedContactName
            }
        }
        else {
            return {
                Name: UtilityService.maskPhoneNumberOnLength(number)
            }
        }
    })

    numbers = [...contactsAdded.filter(y => !y.Name).map(n => {
        return {
            Name: n.MaskedPhoneNumber
        }
    }), ...numbers]


    let savedContacts = x.numbers.flatMap(number => (contacts.filter(x => x.PhoneNumber == number && !!x.Name)))

    const savedContactPhoneNumbers = savedContacts.map(num => num.PhoneNumber);

    const notSavedContactPhoneNumbers = [...x.numbers.filter(x => !savedContactPhoneNumbers.includes(x)), ...contactsAdded.filter(x => !x.Name).map(y => y.PhoneNumber)];

    let allSavedContacts = [...contactsAdded, ...savedContacts]
    return {
        ID: x.id,
        Type: x.scheduleType as ScheduleMessageType,
        Names: [...[...contactsAdded.filter(y => !!y.Name).map(c => c.Name), ...groupsAdded.map(g => g.Name)].sort((a, b) => a > b ? -1 : a < b ? 1 : 0), ...numbers.map(n => n.Name)],
        PhoneNumberID: x.phoneNumberId,
        PhoneNumbers: numbers.filter(x => notSavedContactPhoneNumbers.flatMap(x => x.replace("+", "")).includes(UtilityService.unmaskPhoneNumber(x.Name))).map(x => UtilityService.unmaskPhoneNumber(x.Name)),
        Message: x.body,
        Status: x.status as ScheduleMessageStatus,
        IsDeleted: x.isDeleted,
        CreatedDateTime: x.createdDateTime,
        LastSentDateTime: x.lastSent,
        NextScheduledDateTime: x.nextScheduled,
        Groups: groupsAdded.length ? groupsAdded : x.groups.map((x: string) => { return { ID: x } as Group }),
        Contacts: [...allSavedContacts?.length ? allSavedContacts?.filter(y => !!y.Name) : x.contacts.map((x: string) => { return { ID: x } as Contact })],
        CronExpression: x.cronExpression,
        Media: x.media ? { ID: x.media?.id, Name: x.media?.name, ContentType: x.media?.contentType, Url: `${Config.API_URL}/api/File/${x.media?.id}/view` } as MediaModel : undefined,
        Campaign: x?.campaign ? { ID: x.campaign.id, ToPhoneNumbers: x.campaign.toPhoneNumbers, Name: x.campaign.name, CreatedDateTime: x.campaign.createdDateTime, CreatedById: x.campaign.createdById } as CampaignModel : null,
    } as ScheduledMessageModel;
}

export enum ScheduleMessageType {
    ONE_TIME = 0,
    DAILY,
    WEEKLY,
    MONTHLY
}
export enum ScheduleMessageStatus {
    NONE = 0,
    SCHEDULED,
    SENT,
    STOPPED,
}

export enum ScheduleEmailType {
    WEEKLY = 0,
    MONTHLY
}